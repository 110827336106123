<template>
  <div class="p-3">
    <policeCheckViewCom :propsData="getPoliceCheckSingle"></policeCheckViewCom>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import policeCheckViewCom from "../../components/policeCheck/policeCheckViewCom.vue";

export default {
  name: "policeCheckView",
  components: {
    policeCheckViewCom,
  },
  data() {
    return {
      policeCheckId: encodeDecodeService.decode(this.$route.params.id),
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle"]),
  },
  created() {
    this.fetchPoliceCheckSingle(this.policeCheckId);
  }
};
</script>
