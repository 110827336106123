<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-header">
          <h5 class="text-info mb-0">Review12345</h5>
        </div>
        <div class="card-body">
          <div class="card-columns">
            <!-- Check Details -->
            <appPcCheckDetails :propsData="propsData"> </appPcCheckDetails>

            <!-- Personal Details -->
            <appPcPersonalDetails :propsData="propsData"></appPcPersonalDetails>

            <!-- Identity Verification Photo -->
            <appPcIdentityVerificationPhoto
              :propsData="propsData"
            ></appPcIdentityVerificationPhoto>

            <!-- Current Address Details -->
            <appPcCurrentAddressDetails
              :propsData="propsData"
            ></appPcCurrentAddressDetails>

            <!-- Upload Identity Documents -->
            <appPcUploadIdentityDocuments
              :propsData="propsData"
            ></appPcUploadIdentityDocuments>

            <!-- Additional Details -->
            <appPcAdditionalDetails
              :propsData="propsData"
            ></appPcAdditionalDetails>

            <!-- This will show if this police check send by Individual user -->
            <div
              v-if="
                propsData.sh_id == 1 ||
                propsData.sh_id == 3 ||
                propsData.sh_id == null
              "
            >
              <appPcConsentTypeType1 :propsData="propsData">
              </appPcConsentTypeType1>
            </div>

            <!-- This will show if this police check send by Partner or Partner Staff -->
            <div v-if="propsData.sh_id == 2">
              <appPcConsentTypeType2
                :propsData="propsData"
              ></appPcConsentTypeType2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <pcCheckListCom
        :propsData="propsData"
        v-if="cvs_all_can_access() == true"
      ></pcCheckListCom>

      <div class="card" v-if="cvs_all_can_access() == true">
        <div class="card-header">
          <h5 class="text-info mb-0">Status Change</h5>
        </div>
        <div class="card-body">
          <statusChangeCom
            :id="propsData.id"
            :projectId="propsData.project_id"
            :productId="propsData.product_id"
            :status="propsData.status"
            @fnUpdateStatus="updatePoliceCheckStatus"
          ></statusChangeCom>
        </div>
      </div>

        <!-- Admin/Staff can view notes -->
        <checkListNotesCom
          v-if="cvs_all_can_access()"
          :noteType="'private'"
          :noteId="noteId"
          :checkId="propsData.id"
          :productId="propsData.product_id"
        ></checkListNotesCom>

        <!-- Any other can view notes -->
        <checkListNotesPublicCom
          :noteType="'public'"
          :noteId="notePublicId"
          :checkId="propsData.id"
          :productId="propsData.product_id"
        ></checkListNotesPublicCom>

    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
import * as policeCheckService from "../../services/policeCheck.service";

import appPcCheckDetails from "./pcCheckDetails.vue";
import appPcPersonalDetails from "./pcPersonalDetails.vue";
import appPcIdentityVerificationPhoto from "./pcIdentityVerificationPhoto.vue";
import appPcCurrentAddressDetails from "./pcCurrentAddressDetails.vue";
import appPcUploadIdentityDocuments from "./pcUploadIdentityDocuments.vue";
import appPcAdditionalDetails from "./pcAdditionalDetails.vue";
import appPcConsentTypeType1 from "./pcConsentTypeType1.vue";
import appPcConsentTypeType2 from "./pcConsentTypeType2.vue";
import pcCheckListCom from "./pcCheckListCom.vue";
import statusChangeCom from "../common/StatusChangeCom.vue";
import checkListNotesCom from "../notes/CheckListNotesCom.vue";
import checkListNotesPublicCom from "../notes/CheckListNotesPublicCom.vue";

export default {
  name: "policeCheckViewCom",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
    propsNotesData: {
      type: Object,
    },
    noteId:{
      required:false
    },
    notePublicId:{
      required:false
    }
  },
  components: {
    appPcCheckDetails,
    appPcPersonalDetails,
    appPcIdentityVerificationPhoto,
    appPcCurrentAddressDetails,
    appPcUploadIdentityDocuments,
    appPcAdditionalDetails,
    appPcConsentTypeType1,
    appPcConsentTypeType2,
    pcCheckListCom,
    statusChangeCom,
    checkListNotesCom,
    checkListNotesPublicCom,
  },
  data() {
    return {
      policeCheckId: encodeDecodeService.decode(this.$route.params.id),
      loginUserObj: {},
    };
  },
  computed: {
    ...mapGetters([
      "getPoliceCheckListPaginateCurrent",
    ]),
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle", "fetchPoliceCheckListPaginate"]),

    /**
     * Update police check status
     * This one need to relocated in to view component later
     */
    updatePoliceCheckStatus: async function (obj) {
      try {
        this.isLoaderStatusChange = true;
        this.errors = {};

        /**
         * Update police check status
         */
        await policeCheckService.updateStatus(
          obj.id, // Police check id
          { status: obj.new_status } // new Status
        );

        /**
         * Get police check single based on Police check id
         */
        await this.fetchPoliceCheckSingle(obj.id);

        /**
         * Refresh Police check list
         */
        this.fetchPoliceCheckListPaginate(
          this.getPoliceCheckListPaginateCurrent
        );

        messageService.fnToastSuccess(
          "Police check status - Successfully updated"
        );

        this.isLoaderStatusChange = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Police check status",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Police check status",
              "Something wrong"
            );
            break;
        }
        this.isLoaderStatusChange = false;

        console.log("updatePoliceCheckStatus", error);
      }
    },

    /**
     * CVS parties can access
     * Admin/Staff
     */
    cvs_all_can_access() {
      if (this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();
  },
};
</script>
